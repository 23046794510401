<template>
  <div class="card-meeting" @click="clickMeeting">
    <div class="dates main">
      <div class="dates-wrapper">
        <div class="flex-align" style="gap:8px">
          <div class="date-text">{{ datesFormat(card.date, "date_10") }}</div>
          <div class="d-day" v-if="dDay > 0">D-{{ dDay }}</div>
        </div>
        <div>
          {{ datesFormat(card.date + " " + card.start_time, "time_2") }} ~
          {{ datesFormat(card.date + " " + card.end_time, "time_2") }}
        </div>
      </div>
      <img src="/static/icon/u_angle-right.svg" class="svg-sub4 ic-right" />
    </div>
    <div class="flex" style="gap:8px">
      <img
        :src="card.product.img"
        :alt="card.product.name"
        class="product-img"
        :style="imgStyle"
      />
      <div>
        <div class="body6 sub2">{{ card.product.partner_nickname }}</div>
        <div class="body4-medium main">{{ card.product.name }}</div>
      </div>
    </div>
    <div class="flex-between" style="gap:8px;align-items:flex-start">
      <div style="flex:1">
        <div class="body4 main">{{ card.title }}</div>
        <div class="body6 sub3 ellipsis-1" v-if="card.memo || card.memo_user">{{ card.memo ? card.memo : card.memo_user }}</div>
      </div>
      <button class="button button-edit" style="width:58px">변경</button>
    </div>
    <div class="flex" style="gap:8px" @click.stop>
      <a :href="card.link" target="_blank" style="flex:1">
        <button class="button is-primary body4-medium" style="width:100%">
          온라인 미팅 참여
        </button>
      </a>
      <button
        class="button flex-center"
        style="width:40px;height:40px"
        @click="clickInquiry"
      >
        <div class="position-relative" style="height:18px">
          <img
            src="/static/icon/u_comment.svg"
            style="width:18px;height:18px"
          />
          <div class="new-noti" v-if="card.unread"></div>
        </div>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "CardMeeting",
  components: {},
  mixins: [],
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    dDay() {
      const date = new Date(this.card.date + " " + this.card.start_time);
      const now = new Date();
      const diffTime = date - now;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;
      return diffDays;
    },
    imgStyle() {
      const [width, height] = this.imgRate(33);
      return {
        width: `${width}px`,
        height: `${height}px`,
        objectFit: "cover",
      };
    },
  },
  watch: {},
  mounted() {},
  methods: {
    clickInquiry() {
      let url = `/template_manufacture_detail?id=${this.card.inquiry}`;
      if(this.$route.path.includes('partner_center')) {
        url += '&partner=true';
      }
      this.$router.push(url);
    },
    clickMeeting() {
      this.$emit("update", this.card.id);
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.card-meeting
  display grid
  grid-template-columns 180px 1fr 1fr 180px
  gap 16px
  padding 12px 16px
  border-bottom 1px solid $gray
  cursor pointer
  &:hover
    background-color $gray4
  .date-text
    font-weight 400
  .ic-right
    display none
.ellipsis-1
  height 18px
.d-day
  color $error
  background-color $error50
  border-radius 8px
  padding 2px 8px
  font-size 12px
.product-img
  border-radius 5px
  border 1px solid $gray2
.new-noti
  position absolute
  top -1px
  right -2px
  width 10px
  height 10px
  border-radius 50%
  background-color $error
  border 2px solid white
.button
  height 40px

@media screen and (max-width: 1024px)
  .card-meeting
    grid-template-columns 1fr
    border 1px solid $gray
    border-radius 8px
    background-color white
    .dates
      display flex
      align-items center
      justify-content space-between
      gap 4px
      font-weight 700
      .dates-wrapper
        display flex
        align-items center
        gap 4px
      .date-text
        font-weight 700
      .ic-right
        display block
    .d-day
    .button-edit
      display none
    .button
      height 40px

@media screen and (max-width: 360px)
  .dates-wrapper
    font-size 13px
</style>
