<template>
  <div class="my-mettings">
    <div class="container">
      <div class="mettings-title">미팅 관리</div>
      <div class="tabs">
        <div
          class="tab unselect"
          v-for="(tab, index) in tabs"
          :key="`tab-${index}`"
          @click="clickTab(tab.value)"
          :class="{ active: selectedStatus === tab.value }">
          {{ tab.label }}
        </div>
      </div>
      <div class="list-wrapper">
        <div class="body4 sub total-count" v-if="page.total > 0">전체 {{ page.total }}개</div>
        <div class="list">
            <div class="list-header">
              <div class="list-header-item">미팅일</div>
              <div class="list-header-item">템플릿</div>
              <div class="list-header-item">미팅 정보</div>
              <div class="list-header-item">미팅 링크</div>
            </div>
            <div class="list-item" v-for="(item, index) in list" :key="`item-${index}`">
              <card-meeting :card="item" @update="clickMeeting"/>
            </div>
            <div class="empty-view" v-if="list.length === 0">
              <div class="empty-view-text">등록된 미팅이 없습니다.</div>
            </div>
        </div>
        <lp-paginate v-if="page.total_page > 1" :filtered="page" @getData="getData" />
      </div>
    </div>
    <meeting-modal ref="meetingModal" @update="onUpdate" />
  </div>
</template>
<script>
import CardMeeting from "@/components/component/CardMeeting.vue";
import MeetingModal from "@/components/component/MeetingModal.vue";
import UserAPIMixin from "../../mixins/UserAPIMixin";
import PartnerAPIMixin from "../../mixins/PartnerAPIMixin";
import LpPaginate from "@/components/component/LpPaginate";
export default {
  name: "MyMeetings",
  components: {
    CardMeeting,
    MeetingModal,
    LpPaginate
  },
  mixins: [
    UserAPIMixin,
    PartnerAPIMixin
  ],
  props: {},
  data() {
    return {
      selectedStatus: "before",
      tabs: [
        {
          label: "예정",
          value: "before",
        },
        {
          label: "종료",
          value: "after",
        },
      ],
      page: {
        cur_page: 1,
        total: 0,
        total_page: 0,
        page_length: 10,
      },
      list: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getData();
  },
  methods: {
    onUpdate() {
      this.page.cur_page = 1;
      this.getData();
    },
    clickMeeting(id) {
      this.$refs.meetingModal.open(id);
    },
    getData() {
      const now = this.dayjs().format("YYYY-MM-DD HH:mm:ss");
      const params = {
        page_num: this.page.cur_page,
        page_length: this.page.page_length
      };
      if(this.selectedStatus === "before") {
        params.now = now;
      } else if(this.selectedStatus === "after") {
        params.past = now;
      }
      this.request[this.$route.path.includes('partner') ? 'partner' : 'user'].get('/launchpack/meeting', {
        params: params
      }).then((res) => {
        this.list = res.data.data
        this.page.total = res.data.count
        this.page.total_page = res.data.total_page
      })
    },
    clickTab(value) {
      this.selectedStatus = value;
      this.getData();
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.my-mettings
  display flex
  justify-content center
  .container
    width 1152px
    padding 40px 0
    .mettings-title
      font-size 24px
      font-weight 700
      line-height 36px
      color $main
      margin-bottom 24px
    .tabs
      display flex
      gap 8px
      .tab
        padding 7px 14px
        border-radius 50px
        border 1px solid $gray
        background-color white
        font-size 14px
        font-weight 500
        &.active
          background-color $main
          color white
    .list
      display flex
      flex-direction column
      padding 24px
      border-radius 12px
      background-color white

    .list-wrapper
      display flex
      flex-direction column
      gap 16px
      margin-top 24px
      .empty-view
        padding 100px 0
        font-size 16px
    .list-header
      background-color $gray3
      border-radius 8px
      display grid
      grid-template-columns 180px 1fr 1fr 180px
      gap 16px
      padding 12px 16px
      .list-header-item
        font-size 13px
        font-weight 500
        line-height 21px
        color $sub2

@media screen and (max-width: 1024px)
  .my-mettings
    .container
      width 100%
      padding 24px 0
      .mettings-title
        padding 0 16px
        font-size 18px
        line-height 24px
        margin-bottom 16px
      .tabs
        padding 0 16px
      .list-wrapper
        padding 0 16px
        .total-count
        .list-header
          display none
        .list
          background-color transparent
          gap 8px
          padding 0

</style>
